import { Link, useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import type { Playlist } from '~/models/playlist.server';
import type { Project, ProjectPaginatedResult } from '~/models/project.server';
import ProjectCard from '../ProjectCard';
import { formatProjectForCard } from '~/utils/projects';
import { IconArrowRight } from '@fastly/beacon-icons';

import './playlist-card.css';

type FetcherType = {
  playlist: Playlist;
  projects: ProjectPaginatedResult;
};

const PlaylistCard = ({ id }: { id: Playlist['id'] }) => {
  const fetcher = useFetcher<FetcherType>();
  const [playlist, updatePlaylist] = useState<Playlist>();
  const [projects, updateProjects] = useState<Project[]>();

  useEffect(() => {
    if (!fetcher.data && fetcher.state === 'idle') {
      fetcher.load(`/playlist/${id}/actions/info`);
    }

    if (fetcher.data) {
      updatePlaylist(fetcher.data?.playlist);
      updateProjects(fetcher.data?.projects?.items);
    }
  }, [fetcher, id]);

  if (!playlist || !projects) {
    return;
  }

  return (
    <div className="playlist-card">
      <Link
        to={`/playlist/${playlist.fullUrl}`}
        className="playlist-card-title"
      >
        <h2>{playlist.name}</h2>
      </Link>
      <ReactMarkdown>{playlist.description}</ReactMarkdown>
      <ul className="playlist-card-list">
        {projects?.map((project) => (
          <li key={project.id}>
            <ProjectCard
              size="listing"
              showDescription
              {...formatProjectForCard(project)}
            />
          </li>
        ))}
      </ul>
      <Link
        className="playlist-card-view-all link"
        to={`/playlist/${playlist.fullUrl}`}
      >
        View all <IconArrowRight />
      </Link>
    </div>
  );
};

export default PlaylistCard;
