import { Link } from '@remix-run/react';
import './content-module.css';

export type ContentModuleProps = {
  title: string;
  description: React.ReactNode | string;
  linkText?: string;
  linkHref?: string;
  imageUrl: string;
  imageAlt: string;
  imagePosition: 'left' | 'right' | 'full-width';
  primaryColor?: string;
};

const ContentModule = ({ title, description, linkText, linkHref, imageUrl, imageAlt, imagePosition = 'left', primaryColor }: ContentModuleProps) => {
  return (
    <div className={`content-module ${primaryColor}`}>
      <div className={`content-module-wrapper content-module-image-${imagePosition}`}>
        <img src={imageUrl} alt={imageAlt} />
        <div className="content-module-header">
          <h2>{title}</h2>
        </div>
        <div className="content-module-details">
          {description}
          {linkHref && linkText && (
            <Link className="link content-module-cta" to={linkHref}>
              {linkText}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentModule;
