import { Link } from '@remix-run/react';
import RemixCardGrid from '~/components/index/RemixCardGrid';
import ContentModule from '~/components/shared/ContentModule';
import PlaylistCard from '~/components/shared/PlaylistCard';

const featuredApp = {
  sectionTitle: 'App of the week!',
  bannerURL:
    'https://cdn.glitch.global/69574380-83fa-4367-89f2-feebd49ca206/dotcats.png?v=1733778466628',
  bannerAlt:
    'On a grid, miniature cats hunt and play on an html canvas complete with cat territories, tiny frogs, mice, and kittens.',
  domain: 'dot-cats',
  username: 'bengarvey',
  description:
    'Watch as these dot cats live out their lives on an html canvas.',
  actionText: 'Submit your app to be featured',
  actionURL: 'https://forms.gle/3rm2MsqaPqrha2DH6',
};

const Index = () => {
  return (
    <div className="page-content-wrapper">
      <section className="section">
        <ContentModule
          title="Welcome to the future of Glitch!"
          description={
            <>
              <p className="paragraph">
                Let’s build a new Glitch together! The site you see here is the
                result of the open source ethos of creating together in public
                where the Glitch team and the Glitch community build the next
                version of the Glitch we want to see.
              </p>
              <p className="paragraph">
                <Link
                  className="link"
                  target="_blank"
                  to="https://support.glitch.com/t/sneak-peek-glitch-development-preview/64190"
                >
                  Help us build
                </Link>{' '}
                and share what you think belongs here, too!
              </p>
            </>
          }
          imageUrl="https://cdn.glitch.com/02863ac1-a499-4a41-ac9c-41792950000f%2Fwayback_importer.png?v=1536570052496"
          imageAlt=""
          imagePosition="left"
        />
      </section>
      <section className="section">
        <h2 className="header-2">What do you want to make today?</h2>
        <RemixCardGrid />
      </section>
      <section className="section">
        <div className="grid-2">
          <ContentModule
            title="Deliver your Glitch site through Fastly"
            description={
              <>
                Remix{' '}
                <Link className="link" to="project/learn-edge-computing">
                  learn-edge-computing
                </Link>{' '}
                and learn how to create a Fastly Compute app that changes the
                behavior of your Glitch website at the edge. Customize the
                experience you give your users, like tailoring the display to
                their location!
              </>
            }
            imageUrl="https://cdn.glitch.global/605e2a51-d45f-4d87-a285-9410ad350515/learn-compute-emoji.webp?v=1726151724790"
            imageAlt=""
            imagePosition="full-width"
            primaryColor="primary-4"
          />
          <ContentModule
            title={featuredApp.sectionTitle}
            description={
              <>
                <Link className="link" to={`/project/${featuredApp.domain}`}>
                  {featuredApp.domain}
                </Link>{' '}
                by{' '}
                <Link className="link" to={`/user/${featuredApp.username}`}>
                  {featuredApp.username}
                </Link>
                <p className="paragraph">{featuredApp.description}</p>
                <Link className="link" to={featuredApp.actionURL}>
                  {featuredApp.actionText}
                </Link>
              </>
            }
            imageUrl={featuredApp.bannerURL}
            imageAlt={featuredApp.bannerAlt}
            imagePosition="full-width"
            primaryColor="primary-1"
          />
        </div>
      </section>
      <section className="section">
        <div className="grid-2">
          <ContentModule
            title="Show off your projects!"
            description={
              <>
                <Link
                  className="link"
                  to="https://support.glitch.com/c/the-gallery/6"
                >
                  This is the spot
                </Link>{' '}
                to show off what you’ve built with Glitch, and see what else the
                community is creating. Get feedback from creators, or find a new
                friend to collaborate with – sharing is the first step!
              </>
            }
            imageUrl="https://cdn.glitch.com/2bdfb3f8-05ef-4035-a06e-2043962a3a13%2F2_1.jpg?v=1540471662473"
            imageAlt=""
            imagePosition="full-width"
            primaryColor="primary-2"
          />
          <ContentModule
            title="Get experimental"
            description={
              <>
                Did you know that we have a community testing group? Join the{' '}
                <Link
                  className="link"
                  to="https://support.glitch.com/t/signup-thread-glitch-community-testing/62878"
                >
                  Glitch Community Testing program
                </Link>{' '}
                to try out new features before anyone else.
              </>
            }
            imageUrl="https://cdn.glitch.com/2bdfb3f8-05ef-4035-a06e-2043962a3a13%2Fglitch04_zealous-mammal.png?v=1548074746239"
            imageAlt=""
            imagePosition="full-width"
            primaryColor="primary-3"
          />
        </div>
      </section>
      <section className="section">
        <ContentModule
          title="The open social web is here!"
          description={
            <p className="paragraph">
              Major Fediverse projects, like ActivityPub and Mastodon, are built
              in collaboration with developers, allowing rapid learning and
              iteration, along with the possibility for new apps and
              experiences. This growing community is one of the latest examples
              of the way moving toward a more open web experience benefits us
              all.
            </p>
          }
          linkText="Build for the fediverse with the Glitch community guide!"
          linkHref="https://glitch.com/fediverse"
          imageUrl="https://cdn.glitch.global/605e2a51-d45f-4d87-a285-9410ad350515/fediverse-header-compressed.jpg?v=1687528745870"
          imageAlt=""
          imagePosition="full-width"
        />
      </section>
      <section className="section">
        <div className="grid-2">
          <PlaylistCard id={180718} />
          <ContentModule
            title="Create something for fun"
            description={
              <p className="paragraph">
                Each month, the{' '}
                <Link className="link" to="https://glitch.com/jams">
                  Glitch Community Jams
                </Link>{' '}
                on creating something based on a different theme, just for fun.
                No experience necessary, just a little imagination! Join us at
                the beginning of the month for{' '}
                <Link
                  className="link"
                  to="https://www.youtube.com/playlist?list=PLkkL3Pim_xkWKMmXOMX1KjJkCkFjQv2MB"
                >
                  Glitch Jams Live
                </Link>
                , where we show off cool projects made by the community and even
                code along together!
              </p>
            }
            imageUrl="https://cdn.glitch.global/605e2a51-d45f-4d87-a285-9410ad350515/code-jams-homepage.jpg?v=1717698961965"
            imageAlt=""
            imagePosition="full-width"
            primaryColor="primary-4"
          />
        </div>
      </section>
    </div>
  );
};

export default Index;
